import * as React from "react"
import { useMemo, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Row, Col, Card, Table, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { State } from "../../state/reducer"
import { getAssessments } from "../../state/assessments/actions"
import { useTenantSpecification } from "../tenancy/TenantHooks"
import { TenantConditionSpecification } from "mco-tenant-specifications"

function CodeNameTooltip({
	codeName,
	text
}: {
	codeName: string
	text: string
}) {
	return (
		<OverlayTrigger
			popperConfig={{
				modifiers: [
					{
						name: "preventOverflow",
						options: {
							enabled: true,
							boundariesElement: "viewport"
						}
					}
				]
			}}
			overlay={<Tooltip id={`tooltip-${codeName}`}>{codeName}</Tooltip>}
		>
			<span>{text}</span>
		</OverlayTrigger>
	)
}

function ConditionAssessments({
	condition
}: {
	condition: TenantConditionSpecification
}) {
	const assessments = useSelector((state: State) => state.assessments.details)
	const distinctAssessmentCodeNames = Array.from(
		new Set(condition.AssessmentFamily.CodeNames)
	)
	const conditionAssessments = distinctAssessmentCodeNames.map(
		(c) => assessments[c]
	)

	return (
		<Row className="my-3">
			<Col md={12}>
				<Card>
					<Card.Header>
						<h2 className="h6 mb-0">Details</h2>
					</Card.Header>
					<Card.Body>
						<Table>
							<thead>
								<tr>
									<th scope="col">Type</th>
									<th scope="col">Friendly Name</th>
									<th scope="col">Medical Name</th>
									<th scope="col">Surveys</th>
								</tr>
							</thead>
							<tbody>
								{conditionAssessments.map((a) => (
									<tr key={a.myCodeName}>
										<td>
											<p>{a.type}</p>
											<p>({a.myCodeName})</p>
										</td>
										<td>
											<CodeNameTooltip
												codeName={a.myCodeName}
												text={a.friendlyName}
											/>
										</td>
										<td>
											<CodeNameTooltip
												codeName={a.myCodeName}
												text={a.medicalName}
											/>
										</td>
										<td>
											<Table>
												<thead>
													<tr>
														<th scope="col">
															Friendly Name
														</th>
														<th scope="col">
															Medical Name
														</th>
														<th scope="col">
															Score Provider
														</th>
													</tr>
												</thead>
												<tbody>
													{a.surveys.map((s) => (
														<tr key={s.myCodeName}>
															<td>
																<p>
																	{
																		s.friendlyName
																	}
																</p>
																<p>
																	(
																	{
																		s.myCodeName
																	}
																	)
																</p>
															</td>
															<td>
																<CodeNameTooltip
																	codeName={
																		s.myCodeName
																	}
																	text={
																		s.medicalName
																	}
																/>
															</td>
															<td>
																<ul>
																	{s.scoreProviderFamily.map(
																		(
																			sp
																		) => (
																			<li
																				key={
																					sp.codeName
																				}
																			>
																				<CodeNameTooltip
																					codeName={
																						sp.codeName
																					}
																					text={
																						sp.name
																					}
																				/>
																			</li>
																		)
																	)}
																</ul>
															</td>
														</tr>
													))}
												</tbody>
											</Table>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

function ConditionAssessmentCreationRules({
	condition
}: {
	condition: TenantConditionSpecification
}) {
	return (
		<Row className="my-3">
			<Col md={12}>
				<Card>
					<Card.Header>
						<h2 className="h6 mb-0">Assessment creation rules</h2>
					</Card.Header>
					<Card.Body>
						<ol>
							{condition.AssessmentFamily.ScheduledCreationStrategies.map(
								(x, i) => (
									<li key={i}>{x.Description}</li>
								)
							)}
						</ol>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

function ConditionSchedule({
	condition
}: {
	condition: TenantConditionSpecification
}) {
	const schedules = condition.AssessmentFamily.SurveySchedule.Schedules

	return (
		<Row className="my-3">
			<Col md={12}>
				<Card>
					<Card.Header>
						<h2 className="h6 mb-0">Schedule</h2>
					</Card.Header>
					<Card.Body>
						<Table>
							<thead>
								<tr>
									<th scope="col">Matches</th>
									<th scope="col">Schedule</th>
								</tr>
							</thead>
							<tbody>
								{schedules.map((x, i) => (
									<tr key={i}>
										<td>{x.AcceptsDescription}</td>
										<td>{x.ScheduleDescription}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

function AssessmentDescription({ conditionKey }: { conditionKey: string }) {
	const { Conditions: conditions } = useTenantSpecification()
	const navigate = useNavigate()

	const condition = useMemo(
		() => conditions.find((x) => x.MyUri === conditionKey),
		[conditionKey, conditions]
	)

	useEffect(() => {
		if (conditionKey && !condition) {
			navigate({ search: "" })
		}
	}, [conditionKey, condition, navigate])

	if (!condition) {
		return null
	}

	return (
		<>
			<ConditionAssessmentCreationRules condition={condition} />
			<ConditionSchedule condition={condition} />
			<ConditionAssessments condition={condition} />
		</>
	)
}

export function Assessments() {
	const { Conditions: conditions } = useTenantSpecification()
	const navigate = useNavigate()
	const location = useLocation()
	const search = useMemo(
		() => new URLSearchParams(location.search),
		[location.search]
	)
	const selectedCondition = search.get("condition") || ""
	const dispatch = useDispatch()
	const assessmentsLoaded = useSelector(
		(state: State) => state.assessments.loaded
	)
	useEffect(() => {
		if (assessmentsLoaded) {
			return
		}
		dispatch(getAssessments())
	}, [assessmentsLoaded, dispatch])

	if (!assessmentsLoaded) {
		return null
	}

	const handleSelectedConditionChange = (
		e: React.ChangeEvent<HTMLSelectElement>
	) => {
		search.set("condition", e.target.value)
		navigate({ search: search.toString() })
	}

	return (
		<>
			<Row className="my-3">
				<Col>
					<Card>
						<Card.Header>
							<h2 className="h6 mb-0">Select condition</h2>
						</Card.Header>
						<Card.Body>
							<Row>
								<Col className="col-md-2">
									<label htmlFor="selectCondition">
										Condition
									</label>
								</Col>
								<Col className="col-md-10">
									<select
										id="selectCondition"
										value={selectedCondition}
										className="form-control"
										style={{ maxWidth: "250px" }}
										onChange={handleSelectedConditionChange}
									>
										<option disabled value="">
											-- Select a condition --
										</option>
										{conditions.map((c) => (
											<option
												key={c.MyUri}
												value={c.MyUri}
											>
												{c.Condition.Name}
											</option>
										))}
									</select>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<AssessmentDescription conditionKey={selectedCondition} />
		</>
	)
}
