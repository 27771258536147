import * as ActionTypes from "./types"
import { LAST_ONE_WINS_VALIDATION } from "../../../middleware/RequestValidationMiddleware"
import TENANTED_API_REQUEST from "../../../middleware/ApiRequestMiddleware"
import { SortingRule } from "react-table"
import { PatientRow } from "./reducer"

export function getSearchPatientsFilters() {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: "/api/admin/patient/filters",
			method: "GET",
			types: [
				ActionTypes.PATIENTS_SEARCH_FILTER_REQUEST,
				ActionTypes.PATIENTS_SEARCH_FILTER_RECEIVE,
				ActionTypes.PATIENTS_SEARCH_FILTER_FAILURE
			]
		}
	}
}

export function updatePatientsSearchFilterSelected(
	filterPipeline: string,
	checked: boolean
): any {
	return {
		type: ActionTypes.PATIENTS_SEARCH_FILTER_UPDATE_SELECTED,
		filterPipeline,
		checked
	}
}

export function updatePatientsSearchToggleColumnsSelected(
	column: string,
	selected: boolean
) {
	return {
		type: ActionTypes.PATIENTS_SEARCH_TOGGLE_COLUMNS_UPDATE_SELECTED,
		column: column,
		selected: selected
	}
}

export function updatePatientsSearchQuery(searchQuery: string): any {
	return {
		type: ActionTypes.PATIENTS_SEARCH_FILTER_QUERY_UPDATE,
		searchQuery: searchQuery
	}
}

export function resetPatientsSearchState() {
	return {
		type: ActionTypes.PATIENTS_SEARCH_RESET
	}
}

export function searchPatients(
	pageIndex: number,
	pageSize: number,
	filterPipeline: string,
	orderBy: string,
	desc: boolean
): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: "/api/admin/patient/list",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				pageIndex,
				pageSize,
				filterPipeline,
				orderBy,
				desc
			}),
			method: "POST",
			[LAST_ONE_WINS_VALIDATION]: true,
			types: [
				ActionTypes.PATIENTS_SEARCH_REQUEST,
				ActionTypes.PATIENTS_SEARCH_RECEIVE,
				ActionTypes.PATIENTS_SEARCH_FAILURE
			]
		}
	}
}

export function updatePageIndex(pageIndex: number) {
	return {
		type: ActionTypes.PATIENTS_SEARCH_PAGE_INDEX_UPDATE,
		page: pageIndex
	}
}

export function updatePageSizeAndIndex(
	newPageSize: number,
	newPageIndex: number
) {
	return {
		type: ActionTypes.PATIENTS_SEARCH_PAGE_Size_AND_INDEX_UPDATE,
		page: newPageIndex,
		pageSize: newPageSize
	}
}

export function updateSorted(newSorted: SortingRule<PatientRow>[]) {
	return {
		type: ActionTypes.PATIENTS_SEARCH_SORTED_UPDATE,
		sorted: newSorted
	}
}
