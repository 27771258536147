export const USER_SET = "USER_SET"
export const USER_CLEAR = "USER_CLEAR"

export const PROFESSIONAL_DETAILS_REQUEST = "PROFESSIONAL_DETAILS_REQUEST"
export const PROFESSIONAL_DETAILS_RECEIVE = "PROFESSIONAL_DETAILS_RECEIVE"
export const PROFESSIONAL_DETAILS_FAILURE = "PROFESSIONAL_DETAILS_FAILURE"

export const PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_RECEIVE =
	"PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_RECEIVE"
export const PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_REQUEST =
	"PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_REQUEST"
export const PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_FAILURE =
	"PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_FAILURE"

export const PROFESSIONAL_LOCK_REQUEST = "PROFESSIONAL_LOCK_REQUEST"
export const PROFESSIONAL_LOCK_RECEIVE = "PROFESSIONAL_LOCK_RECEIVE"
export const PROFESSIONAL_LOCK_FAILURE = "PROFESSIONAL_LOCK_FAILURE"

export const PROFESSIONAL_UNLOCK_REQUEST = "PROFESSIONAL_UNLOCK_REQUEST"
export const PROFESSIONAL_UNLOCK_RECEIVE = "PROFESSIONAL_UNLOCK_RECEIVE"
export const PROFESSIONAL_UNLOCK_FAILURE = "PROFESSIONAL_UNLOCK_FAILURE"

export const CONSULTANT_DELETE_REQUEST = "CONSULTANT_DELETE_REQUEST"
export const CONSULTANT_DELETE_RECEIVE = "CONSULTANT_DELETE_RECEIVE"
export const CONSULTANT_DELETE_FAILURE = "CONSULTANT_DELETE_FAILURE"

export const TEAMMEMBER_DELETE_REQUEST = "TEAMMEMBER_DELETE_REQUEST"
export const TEAMMEMBER_DELETE_RECEIVE = "TEAMMEMBER_DELETE_RECEIVE"
export const TEAMMEMBER_DELETE_FAILURE = "TEAMMEMBER_DELETE_FAILURE"

export const PROFESSIONAL_RESTORE_REQUEST = "PROFESSIONAL_RESTORE_REQUEST"
export const PROFESSIONAL_RESTORE_RECEIVE = "PROFESSIONAL_RESTORE_RECEIVE"
export const PROFESSIONAL_RESTORE_FAILURE = "PROFESSIONAL_RESTORE_FAILURE"

export const PROFESSIONAL_SHAREDTO_SHARING_REQUEST =
	" PROFESSIONAL_SHAREDTO_SHARING_REQUEST"
export const PROFESSIONAL_SHAREDTO_SHARING_RECEIVE =
	" PROFESSIONAL_SHAREDTO_SHARING_RECEIVE"
export const PROFESSIONAL_SHAREDTO_SHARING_FAILURE =
	" PROFESSIONAL_SHAREDTO_SHARING_FAILURE"

export const PROFESSIONAL_SHAREDFROM_SHARING_REQUEST =
	" PROFESSIONAL_SHAREDFROM_SHARING_REQUEST"
export const PROFESSIONAL_SHAREDFROM_SHARING_RECEIVE =
	" PROFESSIONAL_SHAREDFROM_SHARING_RECEIVE"
export const PROFESSIONAL_SHAREDFROM_SHARING_FAILURE =
	" PROFESSIONAL_SHAREDFROM_SHARING_FAILURE"

export const PROFESSIONAL_TREATMENT_CENTERS_UPDATE_REQUEST =
	"PROFESSIONAL_TREATMENT_CENTERS_UPDATE_REQUEST"
export const PROFESSIONAL_TREATMENT_CENTERS_UPDATE_RECEIVE =
	"PROFESSIONAL_TREATMENT_CENTERS_UPDATE_RECEIVE"
export const PROFESSIONAL_TREATMENT_CENTERS_UPDATE_FAILURE =
	"PROFESSIONAL_TREATMENT_CENTERS_UPDATE_FAILURE"

export const PROFESSIONAL_ADD_REQUEST = "PROFESSIONAL_ADD_REQUEST"
export const PROFESSIONAL_ADD_RECEIVE = "PROFESSIONAL_ADD_RECEIVE"
export const PROFESSIONAL_ADD_FAILURE = "PROFESSIONAL_ADD_FAILURE"

export const PROFESSIONAL_USER_UPDATE_REQUEST =
	"PROFESSIONAL_USER_UPDATE_REQUEST"
export const PROFESSIONAL_USER_UPDATE_RECEIVE =
	"PROFESSIONAL_USER_UPDATE_RECEIVE"
export const PROFESSIONAL_USER_UPDATE_FAILURE =
	"PROFESSIONAL_USER_UPDATE_FAILURE"

export const PROFESSIONAL_USER_UPDATE_NOTE_REQUEST =
	"PROFESSIONAL_USER_UPDATE_NOTE_REQUEST"
export const PROFESSIONAL_USER_UPDATE_NOTE_RECEIVE =
	"PROFESSIONAL_USER_UPDATE_NOTE_RECEIVE"
export const PROFESSIONAL_USER_UPDATE_NOTE_FAILURE =
	"PROFESSIONAL_USER_UPDATE_NOTE_FAILURE"

export const GET_TREATMENT_CENTER_TEAMMEMBERS_REQUEST =
	"GET_TREATMENT_CENTER_TEAMMEMBERS_REQUEST"
export const GET_TREATMENT_CENTER_TEAMMEMBERS_RECEIVE =
	"GET_TREATMENT_CENTER_TEAMMEMBERS_RECEIVE"
export const GET_TREATMENT_CENTER_TEAMMEMBERS_FAILURE =
	"GET_TREATMENT_CENTER_TEAMMEMBERS_FAILURE"

export const PROFESSIONAL_USER_ADD_SHARE_REQUEST =
	"PROFESSIONAL_USER_ADD_SHARE_REQUEST"
export const PROFESSIONAL_USER_ADD_SHARE_RECEIVE =
	"PROFESSIONAL_USER_ADD_SHARE_RECEIVE"
export const PROFESSIONAL_USER_ADD_SHARE_FAILURE =
	"PROFESSIONAL_USER_ADD_SHARE_FAILURE"

export const PROFESSIONAL_USER_REMOVE_SHARE_REQUEST =
	"PROFESSIONAL_USER_REMOVE_SHARE_REQUEST"
export const PROFESSIONAL_USER_REMOVE_SHARE_RECEIVE =
	"PROFESSIONAL_USER_REMOVE_SHARE_RECEIVE"
export const PROFESSIONAL_USER_REMOVE_SHARE_FAILURE =
	"PROFESSIONAL_USER_REMOVE_SHARE_FAILURE"

export const PROFESSIONAL_SHARES_REQUEST = "PROFESSIONAL_SHARES_REQUEST"
export const PROFESSIONAL_SHARES_RECEIVE = "PROFESSIONAL_SHARES_RECEIVE"
export const PROFESSIONAL_SHARES_FAILURE = "PROFESSIONAL_SHARES_FAILURE"

export const PROFESSIONAL_SHARES_REMOVE_REQUEST =
	"PROFESSIONAL_SHARES_REMOVE_REQUEST"
export const PROFESSIONAL_SHARES_REMOVE_RECEIVE =
	"PROFESSIONAL_SHARES_REMOVE_RECEIVE"
export const PROFESSIONAL_SHARES_REMOVE_FAILURE =
	"PROFESSIONAL_SHARES_REMOVE_FAILURE"
