import * as ActionTypes from "./types"
import TENANTED_API_REQUEST from "../../../middleware/ApiRequestMiddleware"

export function getProfessionalDetails(userId: string): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}`,
			method: "GET",
			types: [
				ActionTypes.PROFESSIONAL_DETAILS_REQUEST,
				ActionTypes.PROFESSIONAL_DETAILS_RECEIVE,
				ActionTypes.PROFESSIONAL_DETAILS_FAILURE
			]
		}
	}
}

export function setUser(user: any) {
	return {
		type: ActionTypes.USER_SET,
		user: user
	}
}

export function clearUser() {
	return {
		type: ActionTypes.USER_CLEAR
	}
}

export function updateProfessionalMedicalAreas(
	userId: string,
	selectedMedicalAreas: string[]
): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}/medicalAreas`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(selectedMedicalAreas),
			types: [
				ActionTypes.PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_REQUEST,
				ActionTypes.PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_RECEIVE,
				ActionTypes.PROFESSIONAL_USER_UPDATE_MEDICAL_AREAS_FAILURE
			]
		}
	}
}

export function lockProfessional(userId: string): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}/lock`,
			method: "PUT",
			types: [
				ActionTypes.PROFESSIONAL_LOCK_REQUEST,
				ActionTypes.PROFESSIONAL_LOCK_RECEIVE,
				ActionTypes.PROFESSIONAL_LOCK_FAILURE
			]
		}
	}
}

export function unlockProfessional(userId: string): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}/unlock`,
			method: "PUT",
			types: [
				ActionTypes.PROFESSIONAL_UNLOCK_REQUEST,
				ActionTypes.PROFESSIONAL_UNLOCK_RECEIVE,
				ActionTypes.PROFESSIONAL_UNLOCK_FAILURE
			]
		}
	}
}

export function deleteConsultant(userId: string): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}`,
			method: "DELETE",
			types: [
				ActionTypes.CONSULTANT_DELETE_REQUEST,
				ActionTypes.CONSULTANT_DELETE_RECEIVE,
				ActionTypes.CONSULTANT_DELETE_FAILURE
			]
		}
	}
}

export function deleteTeamMember(userId: string): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}`,
			method: "DELETE",
			types: [
				ActionTypes.TEAMMEMBER_DELETE_REQUEST,
				ActionTypes.TEAMMEMBER_DELETE_RECEIVE,
				ActionTypes.TEAMMEMBER_DELETE_FAILURE
			]
		}
	}
}

export function restoreProfessional(userId: string): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}/restore`,
			method: "PUT",
			types: [
				ActionTypes.PROFESSIONAL_RESTORE_REQUEST,
				ActionTypes.PROFESSIONAL_RESTORE_RECEIVE,
				ActionTypes.PROFESSIONAL_RESTORE_FAILURE
			]
		}
	}
}

export function getSharedToUsers(userId: string): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}/shares`,
			method: "GET",
			types: [
				ActionTypes.PROFESSIONAL_SHAREDTO_SHARING_REQUEST,
				ActionTypes.PROFESSIONAL_SHAREDTO_SHARING_RECEIVE,
				ActionTypes.PROFESSIONAL_SHAREDTO_SHARING_FAILURE
			]
		}
	}
}

export function getSharedFromUsers(userId: string): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}/sharedwith`,
			method: "GET",
			types: [
				ActionTypes.PROFESSIONAL_SHAREDFROM_SHARING_REQUEST,
				ActionTypes.PROFESSIONAL_SHAREDFROM_SHARING_RECEIVE,
				ActionTypes.PROFESSIONAL_SHAREDFROM_SHARING_FAILURE
			]
		}
	}
}

export function updateProfessionalsTreatmentCenters(
	userId: string,
	treatmentCenterIds: number[]
): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}/treatmentcenters`,
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(treatmentCenterIds),
			types: [
				ActionTypes.PROFESSIONAL_TREATMENT_CENTERS_UPDATE_REQUEST,
				ActionTypes.PROFESSIONAL_TREATMENT_CENTERS_UPDATE_RECEIVE,
				ActionTypes.PROFESSIONAL_TREATMENT_CENTERS_UPDATE_FAILURE
			]
		}
	}
}

export function addProfessional(professionalModel: any): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: "/api/admin/professional",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(professionalModel),
			types: [
				ActionTypes.PROFESSIONAL_ADD_REQUEST,
				ActionTypes.PROFESSIONAL_ADD_RECEIVE,
				ActionTypes.PROFESSIONAL_ADD_FAILURE
			]
		}
	}
}

export function updateProfessional(professionalModel: any): any {
	return async (dispatch: Function, getState: Function) => {
		const result = await dispatch({
			[TENANTED_API_REQUEST]: {
				endpoint: "/api/admin/professional",
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(professionalModel),
				types: [
					ActionTypes.PROFESSIONAL_USER_UPDATE_REQUEST,
					ActionTypes.PROFESSIONAL_USER_UPDATE_RECEIVE,
					ActionTypes.PROFESSIONAL_USER_UPDATE_FAILURE
				]
			}
		})

		if (result.type === ActionTypes.PROFESSIONAL_USER_UPDATE_RECEIVE) {
			dispatch(getProfessionalDetails(professionalModel.userId))
		}
		return result
	}
}

export function updateProfessionalAdminNotes(
	userId: string,
	note: string
): any {
	const body = new URLSearchParams()
	body.append("note", note)

	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${userId}/notes`,
			method: "PUT",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: body,
			types: [
				ActionTypes.PROFESSIONAL_USER_UPDATE_NOTE_REQUEST,
				{
					type: ActionTypes.PROFESSIONAL_USER_UPDATE_NOTE_RECEIVE,
					meta: note
				},
				ActionTypes.PROFESSIONAL_USER_UPDATE_NOTE_FAILURE
			]
		}
	}
}

export function getTreatmentCenterTeamMembers(treatmentCenterId: number): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/treatmentcenters/${treatmentCenterId}/teammembers`,
			method: "GET",
			types: [
				ActionTypes.GET_TREATMENT_CENTER_TEAMMEMBERS_REQUEST,
				ActionTypes.GET_TREATMENT_CENTER_TEAMMEMBERS_RECEIVE,
				ActionTypes.GET_TREATMENT_CENTER_TEAMMEMBERS_FAILURE
			]
		}
	}
}

export function addShare(ownerUserId: string, targetUserId: string): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${ownerUserId}/share/${targetUserId}`,
			method: "POST",
			types: [
				ActionTypes.PROFESSIONAL_USER_ADD_SHARE_RECEIVE,
				ActionTypes.PROFESSIONAL_USER_ADD_SHARE_REQUEST,
				ActionTypes.PROFESSIONAL_USER_ADD_SHARE_FAILURE
			]
		}
	}
}

export function removeShare(ownerUserId: string, targetUserId: string): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/${ownerUserId}/share/${targetUserId}`,
			method: "DELETE",
			types: [
				ActionTypes.PROFESSIONAL_USER_REMOVE_SHARE_RECEIVE,
				ActionTypes.PROFESSIONAL_USER_REMOVE_SHARE_REQUEST,
				ActionTypes.PROFESSIONAL_USER_REMOVE_SHARE_FAILURE
			]
		}
	}
}

export function addMultipleShares(
	ownerIds: string[],
	targetIds: string[]
): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/shares`,
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ ownerIds, targetIds }),
			types: [
				ActionTypes.PROFESSIONAL_SHARES_REQUEST,
				ActionTypes.PROFESSIONAL_SHARES_RECEIVE,
				ActionTypes.PROFESSIONAL_SHARES_FAILURE
			]
		}
	}
}

export function removeMultipleShares(
	ownerId: string,
	targetIds: string[]
): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/professional/shares`,
			method: "DELETE",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ ownerId, targetIds }),
			types: [
				ActionTypes.PROFESSIONAL_SHARES_REMOVE_REQUEST,
				ActionTypes.PROFESSIONAL_SHARES_REMOVE_RECEIVE,
				ActionTypes.PROFESSIONAL_SHARES_REMOVE_FAILURE
			]
		}
	}
}
