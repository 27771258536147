import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"
import ExtractState from "../../../../models/ExtractState"

const initialSpireWeekly: ExtractState = {
	downloadInProgress: false,
	sendToSftpInProgress: false
}

function spireWeeklyReducer(
	state: ExtractState = initialSpireWeekly,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_SPIRE_WEEKLY_DOWNLOAD_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.EXTRACTS_SPIRE_WEEKLY_DOWNLOAD_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_SPIRE_WEEKLY_DOWNLOAD_FAILURE:
			toast.error("Unable to download Spire Weekly extract")
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_SPIRE_WEEKLY_SEND_TO_SFTP_REQUEST:
			return {
				...state,
				sendToSftpInProgress: true
			}
		case ActionTypes.EXTRACTS_SPIRE_WEEKLY_SEND_TO_SFTP_RECEIVE:
			toast.success("Spire Weekly extract sent to SFTP")
			return {
				...state,
				sendToSftpInProgress: false
			}
		case ActionTypes.EXTRACTS_SPIRE_WEEKLY_SEND_TO_SFTP_FAILURE:
			toast.error("Unable to send Spire Weekly extract to SFTP")
			return {
				...state,
				sendToSftpInProgress: false
			}
	}
	return state
}

export default spireWeeklyReducer
