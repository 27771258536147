import { combineReducers } from "redux"
import dailyReducer, { CircleDailyExtractState } from "./daily/reducer"
import missingTenantPatientIdReducer, {
	MissingTenantPatientIdExtractState
} from "./missingTenantPatientId/reducer"

export interface CircleExtractState {
	daily: CircleDailyExtractState
	missingTenantPatientId: MissingTenantPatientIdExtractState
}

const circleExtractReducer = combineReducers({
	daily: dailyReducer,
	missingTenantPatientId: missingTenantPatientIdReducer
})

export default circleExtractReducer
