export const PROFESSIONALS_SEARCH_FILTER_REQUEST =
	"PROFESSIONALS_SEARCH_FILTER_REQUEST"
export const PROFESSIONALS_SEARCH_FILTER_RECEIVE =
	"PROFESSIONALS_SEARCH_FILTER_RECEIVE"
export const PROFESSIONALS_SEARCH_FILTER_FAILURE =
	"PROFESSIONALS_SEARCH_FILTER_FAILURE"

export const PROFESSIONALS_SEARCH_FILTER_UPDATE_SELECTED =
	"PROFESSIONALS_SEARCH_FILTER_UPDATE_SELECTED"

export const PROFESSIONALS_SEARCH_FILTER_QUERY_UPDATE =
	"PROFESSIONALS_SEARCH_FILTER_QUERY_UPDATE"

export const PROFESSIONALS_SEARCH_RESET = "PROFESSIONALS_SEARCH_RESET"

export const PROFESSIONALS_SEARCH_TOGGLE_COLUMNS_UPDATE_SELECTED =
	"PROFESSIONALS_SEARCH_TOGGLE_COLUMNS_UPDATE_SELECTED"

export const PROFESSIONALS_SEARCH_REQUEST = "PROFESSIONALS_SEARCH_REQUEST"
export const PROFESSIONALS_SEARCH_RECEIVE = "PROFESSIONALS_SEARCH_RECEIVE"
export const PROFESSIONALS_SEARCH_FAILURE = "PROFESSIONALS_SEARCH_FAILURE"

export const PROFESSIONALS_NO_UPDATE_SEARCH_REQUEST =
	"PROFESSIONALS_NO_UPDATE_SEARCH_REQUEST"
export const PROFESSIONALS_NO_UPDATE_SEARCH_RECEIVE =
	"PROFESSIONALS_NO_UPDATE_SEARCH_RECEIVE"
export const PROFESSIONALS_NO_UPDATE_SEARCH_FAILURE =
	"PROFESSIONALS_NO_UPDATE_SEARCH_FAILURE"

export const PROFESSIONALS_SEARCH_PAGE_INDEX_UPDATE =
	"PROFESSIONALS_SEARCH_PAGE_INDEX_UPDATE"
export const PROFESSIONALS_SEARCH_PAGE_Size_AND_INDEX_UPDATE =
	"PROFESSIONALS_SEARCH_PAGE_Size_AND_INDEX_UPDATE"
export const PROFESSIONALS_SEARCH_SORTED_UPDATE =
	"PROFESSIONALS_SEARCH_SORTED_UPDATE"
