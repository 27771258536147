import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"
import ExtractState from "../../../models/ExtractState"

const initialGgcExtract: ExtractState = {
	downloadInProgress: false
}

function ggcExtractReducer(
	state: ExtractState = initialGgcExtract,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_GGC_DOWNLOAD_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.EXTRACTS_GGC_DOWNLOAD_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_GGC_DOWNLOAD_FAILURE:
			toast.error("Unable to download GGC extract")
			return {
				...state,
				downloadInProgress: false
			}
	}
	return state
}

export default ggcExtractReducer
