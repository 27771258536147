import { CSSProperties, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Row, Col } from "react-bootstrap"
import Loading from "../images/loading.svg?react"
import BrowserWarning from "./BrowserWarning"
import { useAuth0 } from "@auth0/auth0-react"
import { finishAuthenticate } from "../state/auth/actions"
import { AdminUser } from "../state/auth/reducer"
import { useIsAuthenticated } from "../hooks/AuthHooks"
import { Navigate } from "react-router-dom"

function Login() {
	const style: CSSProperties = {
		height: "90vh",
		display: "flex",
		justifyContent: "center"
	}

	const {
		error: auth0Error,
		isLoading,
		isAuthenticated: isAuth0Authenticated,
		loginWithRedirect,
		user,
		getAccessTokenSilently
	} = useAuth0<AdminUser>()

	const isAuthenticated = useIsAuthenticated()

	const dispatch = useDispatch()
	useEffect(() => {
		if (isAuthenticated) {
			return
		}

		if (auth0Error || isLoading || isAuth0Authenticated) {
			return
		}

		loginWithRedirect()
	}, [
		isAuthenticated,
		auth0Error,
		isAuth0Authenticated,
		isLoading,
		loginWithRedirect
	])

	useEffect(() => {
		if (!isAuth0Authenticated) {
			return
		}
		if (!user) {
			throw new Error("User not defined in auth0 response")
		}
		const doFinishAuthenticate = async () => {
			const accessToken = await getAccessTokenSilently()
			dispatch(finishAuthenticate(user, accessToken))
		}
		doFinishAuthenticate()
	}, [dispatch, isAuth0Authenticated, user, getAccessTokenSilently])

	if (isAuthenticated) {
		return <Navigate to="" />
	}

	return (
		<Row>
			<BrowserWarning />
			<Col id="loginContainer" md={12}>
				<div style={style}>
					<Loading alt="loading" />
				</div>
			</Col>
		</Row>
	)
}

export default Login
