import { Tenant } from "./Tenant"
import { allTenants } from "./t4/TenantDictionary"
import { retry } from "mco-typescript-core"
import { zoneRegistry } from "mco-zone-configurations"

const subDomains = allTenants.map((x) => x.subDomain)
export type TenantSubDomain = (typeof subDomains)[number]

const internalNames = allTenants.map((x) => x.internalName)
export type TenantInternalName = (typeof internalNames)[number]

const codeNames = allTenants.map((x) => x.codeName)
export type TenantCodeName = (typeof codeNames)[number]

export type TenantOption = {
	subDomain: TenantSubDomain
	internalName: string
	loginsDisabledAfter: string
}

class TenantRegistry {
	public isSubDomain = (test: string): test is TenantSubDomain =>
		typeof test === "string" && (subDomains as string[]).includes(test)

	public getTenantOptions(): TenantOption[] {
		const zone = zoneRegistry.get()

		return allTenants
			.filter(
				(tenant) =>
					!(tenant.disabledZones as readonly string[]).includes(
						zone.codename
					)
			)
			.map((tenant) => {
				return {
					subDomain: tenant.subDomain,
					internalName: tenant.internalName,
					loginsDisabledAfter: tenant.loginsDisabledAfter
				}
			})
			.sort((a, b) => a.internalName.localeCompare(b.internalName))
	}

	public tryGetTenantBySubDomain(subDomain: string) {
		if (!this.isSubDomain(subDomain)) {
			return
		}
		return this.getTenant(subDomain)
	}

	public getTenantBySubDomain(subDomain: string) {
		if (!this.isSubDomain(subDomain)) {
			throw new Error(
				`Can not retrieve tenant by subdomain: ${subDomain}`
			)
		}
		return this.getTenant(subDomain)
	}

	private async getTenant(subDomain: TenantSubDomain) {
		const tenant = allTenants.find((t) => t.subDomain === subDomain)!
		const tenantContainer = (await retry(() => tenant.importer())) as {
			default: Tenant
		}
		return tenantContainer.default
	}

	// For dev page use only
	public getAllTenants() {
		return Promise.all(subDomains.map((x) => this.getTenantBySubDomain(x)))
	}
}

export const tenantRegistry = new TenantRegistry()
