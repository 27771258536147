export const PATIENT_DETAILS_REQUEST = "PATIENT_DETAILS_REQUEST"
export const PATIENT_DETAILS_RECEIVE = "PATIENT_DETAILS_RECEIVE"
export const PATIENT_DETAILS_FAILURE = "PATIENT_DETAILS_FAILURE"

export const PATIENT_LOCK_REQUEST = "PATIENT_LOCK_REQUEST"
export const PATIENT_LOCK_RECEIVE = "PATIENT_LOCK_RECEIVE"
export const PATIENT_LOCK_FAILURE = "PATIENT_LOCK_FAILURE"

export const PATIENT_UNLOCK_REQUEST = "PATIENT_UNLOCK_REQUEST"
export const PATIENT_UNLOCK_RECEIVE = "PATIENT_UNLOCK_RECEIVE"
export const PATIENT_UNLOCK_FAILURE = "PATIENT_UNLOCK_FAILURE"

export const PATIENT_DELETE_REQUEST = "PATIENT_DELETE_REQUEST"
export const PATIENT_DELETE_RECEIVE = "PATIENT_DELETE_RECEIVE"
export const PATIENT_DELETE_FAILURE = "PATIENT_DELETE_FAILURE"

export const PATIENT_RESTORE_REQUEST = "PATIENT_RESTORE_REQUEST"
export const PATIENT_RESTORE_RECEIVE = "PATIENT_RESTORE_RECEIVE"
export const PATIENT_RESTORE_FAILURE = "PATIENT_RESTORE_FAILURE"

export const PATIENT_CONDITION_VALIDATE_REQUEST =
	"PATIENT_CONDITION_VALIDATE_REQUEST"
export const PATIENT_CONDITION_VALIDATE_RECEIVE =
	"PATIENT_CONDITION_DELETE_RECEIVE"
export const PATIENT_CONDITION_VALIDATE_FAILURE =
	"PATIENT_CONDITION_VALIDATE_FAILURE"

export const PATIENT_PMA_DELETE_EXTRACTS_REQUEST =
	"PATIENT_PMA_DELETE_EXTRACTS_REQUEST"
export const PATIENT_PMA_DELETE_EXTRACTS_RECEIVE =
	"PATIENT_PMA_DELETE_EXTRACTS_RECEIVE"
export const PATIENT_PMA_DELETE_EXTRACTS_FAILURE =
	"PATIENT_PMA_DELETE_EXTRACTS_FAILURE"

export const PATIENT_PMA_DELETE_REQUEST = "PATIENT_PMA_DELETE_REQUEST"
export const PATIENT_PMA_DELETE_RECEIVE = "PATIENT_PMA_DELETE_RECEIVE"
export const PATIENT_PMA_DELETE_FAILURE = "PATIENT_PMA_DELETE_FAILURE"

export const PATIENT_USER_UPDATE_REQUEST = "PATIENT_USER_UPDATE_REQUEST"
export const PATIENT_USER_UPDATE_RECEIVE = "PATIENT_USER_UPDATE_RECEIVE"
export const PATIENT_USER_UPDATE_FAILURE = "PATIENT_USER_UPDATE_FAILURE"

export const PATIENT_USER_UPDATE_NOTE_REQUEST =
	"PATIENT_USER_UPDATE_NOTE_REQUEST"
export const PATIENT_USER_UPDATE_NOTE_RECEIVE =
	"PATIENT_USER_UPDATE_NOTE_RECEIVE"
export const PATIENT_USER_UPDATE_NOTE_FAILURE =
	"PATIENT_USER_UPDATE_NOTE_FAILURE"

export const PATIENT_TREATMENT_UPDATE_REQUEST =
	"PATIENT_TREATMENT_UPDATE_REQUEST"
export const PATIENT_TREATMENT_UPDATE_RECEIVE =
	"PATIENT_TREATMENT_UPDATE_RECEIVE"
export const PATIENT_TREATMENT_UPDATE_FAILURE =
	"PATIENT_TREATMENT_UPDATE_FAILURE"

export const PATIENT_TREATMENT_CREATE_REQUEST =
	"PATIENT_TREATMENT_CREATE_REQUEST"
export const PATIENT_TREATMENT_CREATE_RECEIVE =
	"PATIENT_TREATMENT_CREATE_RECEIVE"
export const PATIENT_TREATMENT_CREATE_FAILURE =
	"PATIENT_TREATMENT_CREATE_FAILURE"

export const PATIENT_TREATMENT_DELETE_REQUEST =
	"PATIENT_TREATMENT_DELETE_REQUEST"
export const PATIENT_TREATMENT_DELETE_RECEIVE =
	"PATIENT_TREATMENT_DELETE_RECEIVE"
export const PATIENT_TREATMENT_DELETE_FAILURE =
	"PATIENT_TREATMENT_DELETE_FAILURE"

export const PATIENT_PMA_ALLOW_EMAILS_REQUEST =
	"PATIENT_PMA_ALLOW_EMAILS_REQUEST"
export const PATIENT_PMA_ALLOW_EMAILS_RECEIVE =
	"PATIENT_PMA_ALLOW_EMAILS_RECEIVE"
export const PATIENT_PMA_ALLOW_EMAILS_FAILURE =
	"PATIENT_PMA_ALLOW_EMAILS_FAILURE"

export const PATIENT_PMA_DISALLOW_EMAILS_REQUEST =
	"PATIENT_PMA_DISALLOW_EMAILS_REQUEST"
export const PATIENT_PMA_DISALLOW_EMAILS_RECEIVE =
	"PATIENT_PMA_DISALLOW_EMAILS_RECEIVE"
export const PATIENT_PMA_DISALLOW_EMAILS_FAILURE =
	"PATIENT_PMA_DISALLOW_EMAILS_FAILURE"

export const PATIENT_PMA_GET_CLINICIANS_REQUEST =
	"PATIENT_PMA_GET_CLINICIANS_REQUEST"
export const PATIENT_PMA_GET_CLINICIANS_RECEIVE =
	"PATIENT_PMA_GET_CLINICIANS_RECEIVE"
export const PATIENT_PMA_GET_CLINICIANS_FAILURE =
	"PATIENT_PMA_GET_CLINICIANS_FAILURE"

export const PATIENT_PMA_UPDATE_CLINICIAN_REQUEST =
	"PATIENT_PMA_UPDATE_CLINICIAN_REQUEST"
export const PATIENT_PMA_UPDATE_CLINICIAN_RECEIVE =
	"PATIENT_PMA_UPDATE_CLINICIAN_RECEIVE"
export const PATIENT_PMA_UPDATE_CLINICIAN_FAILURE =
	"PATIENT_PMA_UPDATE_CLINICIAN_FAILURE"

export const PATIENT_FAKE_DATA_CREATE_SINGLE_REQUEST =
	"PATIENTS_FAKE_DATA_CREATE_SINGLE_REQUEST"
export const PATIENT_FAKE_DATA_CREATE_SINGLE_RECEIVE =
	"PATIENTS_FAKE_DATA_CREATE_SINGLE_RECEIVE"
export const PATIENT_FAKE_DATA_CREATE_SINGLE_FAILURE =
	"PATIENTS_FAKE_DATA_CREATE_SINGLE_FAILURE"

export const PATIENT_RESET_REQUEST = "PATIENT_RESET_REQUEST"
export const PATIENT_RESET_RECEIVE = "PATIENT_RESET_RECEIVE"
export const PATIENT_RESET_FAILURE = "PATIENT_RESET_FAILURE"

export const PATIENT_FAKE_DATA_CREATE_PHIN_REQUEST =
	"PATIENT_FAKE_DATA_CREATE_PHIN_REQUEST"
export const PATIENT_FAKE_DATA_CREATE_PHIN_RECEIVE =
	"PATIENT_FAKE_DATA_CREATE_PHIN_RECEIVE"
export const PATIENT_FAKE_DATA_CREATE_PHIN_FAILURE =
	"PATIENT_FAKE_DATA_CREATE_PHIN_FAILURE"

export const PATIENT_FIX_BROKEN_PATIENT_REQUEST =
	"PATIENT_FIX_BROKEN_PATIENT_REQUEST"
export const PATIENT_FIX_BROKEN_PATIENT_RECEIVE =
	"PATIENT_FIX_BROKEN_PATIENT_RECEIVE"
export const PATIENT_FIX_BROKEN_PATIENT_FAILURE =
	"PATIENT_FIX_BROKEN_PATIENT_FAILURE"

export const PATIENT_FIX_NEW_BROKEN_PATIENT_REQUEST =
	"PATIENT_FIX_NEW_BROKEN_PATIENT_REQUEST"
export const PATIENT_FIX_NEW_BROKEN_PATIENT_RECEIVE =
	"PATIENT_FIX_NEW_BROKEN_PATIENT_RECEIVE"
export const PATIENT_FIX_NEW_BROKEN_PATIENT_FAILURE =
	"PATIENT_FIX_NEW_BROKEN_PATIENT_FAILURE"

export const PATIENT_FIX_NEW_BROKEN_PATIENT_CONDITION_REQUEST =
	"PATIENT_FIX_NEW_BROKEN_PATIENT_CONDITION_REQUEST"
export const PATIENT_FIX_NEW_BROKEN_PATIENT_CONDITION_RECEIVE =
	"PATIENT_FIX_NEW_BROKEN_PATIENT_CONDITION_RECEIVE"
export const PATIENT_FIX_NEW_BROKEN_PATIENT_CONDITION_FAILURE =
	"PATIENT_FIX_NEW_BROKEN_PATIENT_CONDITION_FAILURE"
