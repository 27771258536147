import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"
import ExtractState from "../../../models/ExtractState"

const initialExtract: ExtractState = {
	downloadInProgress: false
}

function amlExtractReducer(state: ExtractState = initialExtract, action: any) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_AML_DOWNLOAD_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.EXTRACTS_AML_DOWNLOAD_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_AML_DOWNLOAD_FAILURE:
			toast.error("Unable to download AML extract")
			return {
				...state,
				downloadInProgress: false
			}
	}
	return state
}

export default amlExtractReducer
