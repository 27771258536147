export const EXTRACTS_SPIRE_EPISODENUMBER_UPLOAD_REQUEST =
	"EXTRACTS_SPIRE_EPISODENUMBER_UPLOAD_REQUEST"
export const EXTRACTS_SPIRE_EPISODENUMBER_UPLOAD_RECEIVE =
	"EXTRACTS_SPIRE_EPISODENUMBER_UPLOAD_RECEIVE"
export const EXTRACTS_SPIRE_EPISODENUMBER_UPLOAD_FAILURE =
	"EXTRACTS_SPIRE_EPISODENUMBER_UPLOAD_FAILURE"

export const EXTRACTS_SPIRE_MISSINGEPISODENUMBER_DOWNLOAD_REQUEST =
	"EXTRACTS_SPIRE_MISSINGEPISODENUMBER_DOWNLOAD_REQUEST"
export const EXTRACTS_SPIRE_MISSINGEPISODENUMBER_DOWNLOAD_RECEIVE =
	"EXTRACTS_SPIRE_MISSINGEPISODENUMBER_DOWNLOAD_RECEIVE"
export const EXTRACTS_SPIRE_MISSINGEPISODENUMBER_DOWNLOAD_FAILURE =
	"EXTRACTS_SPIRE_MISSINGEPISODENUMBER_DOWNLOAD_FAILURE"
