import * as React from "react"
import { useEffect, useState } from "react"
import { Row, Card, Form, Col, ListGroup, Table, Container,Button, Collapse } from "react-bootstrap"
import LoadingButton from "../LoadingButton"
import { useSelector, useDispatch } from "react-redux"
import { sendToKafka, getKafkaQuery, replayByOffset } from "../../state/integrations/actions"
import { State } from "../../state/reducer"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Icons from "../Icons"

function KafkaManager() {
    
    const { uploadInProgress, uploadErrors } = useSelector(
		(state: State) => state.integrations.kafkaProduce
	)
    const { startOffset, messages, queryErrors } = useSelector(
        (state: State) => state.integrations.kafkaQuery
    )
    const { replayInProgress, replayErrors } = useSelector(
        (state: State) => state.integrations.kafkaReplay

    )
	const [selectedUploadFile, setSelectedUploadFile] = useState(
		null as unknown as File
	)
    const [open, setOpen] = useState(false)

	const dispatch = useDispatch()

    useEffect(() => {
		dispatch(getKafkaQuery())
	}, [dispatch])

	const handleUploadClick = async() => {
        await dispatch(sendToKafka(selectedUploadFile))
        await dispatch(getKafkaQuery())
    }
	const handleFileChangeClick = (e: any) =>
		setSelectedUploadFile(e.target.files[0])
    const handleReplayOffsetClick = async (offset: number) => {
        await dispatch(replayByOffset(offset))
        await dispatch(getKafkaQuery())
    }
        
    return (
        <div>
            <Row id="info">
                <Col className="col-md-12">
                    <Card className="my-3">
                        <Card.Header>
                            Kafka Manager
                        </Card.Header>
                        <Card.Body>
                            <ul>
                                <li>NHS Wales Cardiff and Vale (CAV) only!</li>
                                <li>If the queue is empty, you'll get an error back.</li>
                                <li>Messages last in the queue for only 7 days.</li>
                                <li>In development, you need the AWS VPN on, which means only Woz and Rob can use it</li>
                            </ul> 
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row id="list">
                <Col className="col-md-12">
                    <Card className="my-3">
                        <Card.Header>
                            Kafka List (Latest 10)
                        </Card.Header>
                        <Card.Body className="p-0">
                            <Container fluid style={{position: "relative" }} className="p-0">
                                
                                <Table size="sm" className="border-top-0 mb-0">
                                    <thead>
                                        <tr>
                                            <th>Topic/Offset</th>
                                            <th>Value</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {startOffset === -1 && 
                                        <tr><td>Querying Kafka...</td></tr>
                                    }
                                    {messages.map((row, i) => ( 
                                        <tr key={i}>
                                            <td>
                                                {row.topic}/{row.offset}
                                            </td>      
                                            <td>
                                                {!open &&
                                                    <pre>
                                                    {row.value.substring(0,128).replace(/\n/g,"").replace(/ {2}/g,"")}...
                                                    </pre>
                                                }
                                                <Collapse in={open}>
                                                    <pre>
                                                        {row.value}
                                                    </pre>
                                                </Collapse>
                                            </td>
                                            <td>
                                                <CopyToClipboard
                                                    text={row.value}
                                                    onCopy={() =>
                                                        toast.success(
                                                            `Copied ${row.topic}/${row.offset} to clipboard`
                                                        )}>
                                                    <Button>
                                                        <FontAwesomeIcon
                                                            icon={Icons.Copy}   
                                                        />
                                                    </Button>
                                                </CopyToClipboard>{' '}
                                                <Button 
                                                    variant="info"
                                                    onClick={() => setOpen(!open)}
                                                >
                                                    {open ? "Hide" : "Show"}
                                                </Button>{' '}
                                                <Button 
                                                    variant="warning"
                                                    onClick={() => handleReplayOffsetClick(row.offset)}
                                                    disabled={replayInProgress}
                                                >
                                                    Replay
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </Table>
                            </Container>
                        </Card.Body>
                        {queryErrors && 
                        <Card.Footer className="bg-danger text-light">
                            {queryErrors}
                        </Card.Footer>}
                        {replayErrors && 
                        <Card.Footer className="bg-danger text-light">
                            {replayErrors}
                        </Card.Footer>}
                    </Card>
                </Col>
            </Row>
            <Row id="producer">
                    <Col className="col-md-12">
                        <Card className="my-3">
                            <Card.Header>Kafka Producer</Card.Header>
                            <Card.Body>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="p-0">
                                    <Form.Group>
                                        <Form.Row>
                                            <Col className="col-md-12">
                                                <p>
                                                    Push messages into the Kafka message queue. List above will update automatically (takes a second or two). Details go to Slack Worker channel.
                                                </p>
                                                <input
                                                    type="file"
                                                    name="file"
                                                    onChange={handleFileChangeClick}
                                                />
                                                
                                            </Col>
                                        </Form.Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Row>
                                            <LoadingButton
                                                variant="warning"
                                                onClick={handleUploadClick}
                                                loading={uploadInProgress}
                                                disabled={!selectedUploadFile}
                                            >
                                        Produce
                                            </LoadingButton>
                                        </Form.Row>
                                    </Form.Group>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                        {uploadErrors && 
                        <Card.Footer className="bg-danger text-light">
                            <p dangerouslySetInnerHTML={{
                                                        __html: uploadErrors
                                                    }} />
                        </Card.Footer>}
                        </Card>
                    </Col>
            </Row>
        </div>
    )
}

export default KafkaManager