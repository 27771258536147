export const TENANT_ENVIRONMENT_REQUEST = "TENANT_ENVIRONMENT_REQUEST"
export const TENANT_ENVIRONMENT_RECEIVE = "TENANT_ENVIRONMENT_RECEIVE"
export const TENANT_ENVIRONMENT_FAILURE = "TENANT_ENVIRONMENT_FAILURE"

export const SET_OVERRIDE_DATE = "SET_OVERRIDE_DATE"

export const TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_REQUEST =
	"TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_REQUEST"
export const TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_RECEIVE =
	"TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_RECEIVE"
export const TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_FAILURE =
	"TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_FAILURE"

export const TENANT_AUTOMATION_GENERATE_ASSESSMENTS_REQUEST =
	"TENANT_AUTOMATION_GENERATE_ASSESSMENTS_REQUEST"
export const TENANT_AUTOMATION_GENERATE_ASSESSMENTS_RECEIVE =
	"TENANT_AUTOMATION_GENERATE_ASSESSMENTS_RECEIVE"
export const TENANT_AUTOMATION_GENERATE_ASSESSMENTS_FAILURE =
	"TENANT_AUTOMATION_GENERATE_ASSESSMENTS_FAILURE"

export const TENANT_DATABASE_MIGRATION_REQUEST =
	"TENANT_DATABASE_MIGRATION_REQUEST"
export const TENANT_DATABASE_MIGRATION_RECEIVE =
	"TENANT_DATABASE_MIGRATION_RECEIVE"
export const TENANT_DATABASE_MIGRATION_FAILURE =
	"TENANT_DATABASE_MIGRATION_FAILURE"
