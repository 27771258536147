import * as ActionTypes from "./types"
import { toast } from "react-toastify"

export type CircleDailyExtractState = {}

const initial: CircleDailyExtractState = {}

function circleDailyExtractReducer(
	state: CircleDailyExtractState = initial,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_CIRCLE_DAILY_EXTRACT_RECEIVE:
			toast.success(
				"Started Circle daily extract. See slack for progress"
			)
			return state
		case ActionTypes.EXTRACTS_CIRCLE_DAILY_EXTRACT_FAILURE:
			toast.error("Unable to download Circle extract")
			return state
	}
	return state
}

export default circleDailyExtractReducer
