import * as ActionTypes from "./types"

export interface AssessmentDetails {
	type: string
	myCodeName: string
	friendlyName: string
	medicalName: string
	surveys: {
		myCodeName: string
		friendlyName: string
		medicalName: string
		scoreProviderFamily: { codeName: string; name: string }[]
	}[]
}

export interface AssessmentsState {
	loaded: boolean
	details: {
		[codeName: string]: AssessmentDetails
	}
}

const initial: AssessmentsState = {
	loaded: false,
	details: {}
}

function assessmentsReducer(state: AssessmentsState = initial, action: any) {
	switch (action.type) {
		case ActionTypes.ASSESSMENTS_GET_REQUEST:
			return { ...initial }
		case ActionTypes.ASSESSMENTS_GET_RECEIVE:
			const assessments = action.payload.reduce(
				(obj: any, assessment: AssessmentDetails) => {
					obj[assessment.myCodeName] = assessment
					return obj
				},
				{}
			)
			return {
				...state,
				loaded: true,
				details: assessments
			}
	}
	return state
}

export default assessmentsReducer
