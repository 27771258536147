import * as ActionTypes from "./types"
import { toast } from "react-toastify"

export interface NhsNumberImportState {
	uploadInProgress: boolean
	uploadErrors: string
}

const initialNhsNumberImport: NhsNumberImportState = {
	uploadInProgress: false,
	uploadErrors: ""
}

function nhsNumberImportReducer(
	state: NhsNumberImportState = initialNhsNumberImport,
	action: any
) {
	switch (action.type) {
		case ActionTypes.IMPORT_NHSNUMBER_UPLOAD_REQUEST:
			return {
				...state,
				uploadInProgress: true,
				uploadErrors: ""
			}
		case ActionTypes.IMPORT_NHSNUMBER_UPLOAD_RECEIVE:
			toast.success("Successfully uploaded NHS Numbers")
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: ""
			}
		case ActionTypes.IMPORT_NHSNUMBER_UPLOAD_FAILURE:
			toast.error("Unable to upload and process the NHS Number extract")
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: action.payload.response.Message
			}
	}
	return state
}

export default nhsNumberImportReducer
