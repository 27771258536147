import { flattenArray } from "../services/ArrayHelpers"

export interface FilterSelector {
	name: string
	filterPipeline: string
	selected: boolean
	count: number
	compoundFilter: Array<Filter>
}

export interface Category {
	name: string
	selectionStrategy: string
	selectors: Array<FilterSelector>
}

interface Filter {
	codeName: string
	value: number | string
}

interface SearchFiltersPayload {
	categories: Array<Category>
	primaryFilter: Filter
	total: number
}

export class SearchFilters {
	constructor(
		public categories: Array<Category>,
		public primaryFilter: Filter,
		public total: number
	) {}

	static CreateFromPayload = (payload: SearchFiltersPayload) =>
		new SearchFilters(
			payload.categories,
			payload.primaryFilter,
			payload.total
		).resetFilters()

	getCompoundFilterSelected(filterPipeline: string) {
		return this.categories
			.map((c) => c.selectors)
			.reduce((accumulator, selectors) => accumulator.concat(selectors))
			.filter((c) => c.filterPipeline === filterPipeline)[0].selected
	}

	resetFilters() {
		const updatedCategories = this.categories.map((category) => {
			let updatedCategory = { ...category }
			updatedCategory.selectors = updatedCategory.selectors.map(
				(selector) => {
					let updatedFilter = { ...selector }
					updatedFilter.selected = false
					return updatedFilter
				}
			)
			return updatedCategory
		})
		return new SearchFilters(
			updatedCategories,
			this.primaryFilter,
			this.total
		)
	}

	updateSelectedInFilters(
		filterPipeline: string,
		checked: boolean
	): SearchFilters {
		const updatedCategories = this.categories.map((category) => {
			let updatedCategory = { ...category }
			updatedCategory.selectors = updatedCategory.selectors.map(
				(selector) => {
					if (selector.filterPipeline !== filterPipeline) {
						return selector
					}
					let updatedFilter = { ...selector }
					updatedFilter.selected = checked
					return updatedFilter
				}
			)
			return updatedCategory
		})
		return new SearchFilters(
			updatedCategories,
			this.primaryFilter,
			this.total
		)
	}

	getFilterPipeline = (
		searchQuery: string,
		searchFilterCodeName: string
	): string | undefined => {
		if (!this.categories || !this.primaryFilter) {
			return undefined
		}

		const selectedFilters = this.categories
			.map((category: Category) => {
				return category.selectors.filter((selector: FilterSelector) => {
					return selector.selected
				})
			})
			.filter(
				(selectedFilterCategory: Array<FilterSelector>) =>
					selectedFilterCategory.length > 0
			)
		const mergedSelectedFilters = flattenArray(selectedFilters)
		const selectedFiltersPipelines = mergedSelectedFilters.map(
			(selectedFilter: FilterSelector) => selectedFilter.filterPipeline
		)
		let filterPipeline =
			selectedFiltersPipelines.length > 0
				? `${
						this.primaryFilter.codeName
				  }|${selectedFiltersPipelines.join("|")}`
				: this.primaryFilter.codeName

		if (searchQuery) {
			filterPipeline += `|${searchFilterCodeName}:'${searchQuery}'`
		}

		return filterPipeline
	}
}
