export const CLIENT_PROFESSIONAL_SSO_AUTH_CODE_GET_REQUEST =
	"CLIENT_PROFESSIONAL_SSO_AUTH_CODE_GET_REQUEST"
export const CLIENT_PROFESSIONAL_SSO_AUTH_CODE_GET_RECEIVE =
	"CLIENT_PROFESSIONAL_SSO_AUTH_CODE_GET_RECEIVE"
export const CLIENT_PROFESSIONAL_SSO_AUTH_CODE_GET_FAILURE =
	"CLIENT_PROFESSIONAL_SSO_AUTH_CODE_GET_FAILURE"

export const CLIENT_PROFESSIONAL_SSO_AUTH_CODE_UPDATE =
	"CLIENT_PROFESSIONAL_SSO_AUTH_CODE_UPDATE"

export const CLIENT_PROFESSIONAL_SSO_TOKEN_POST_REQUEST =
	"CLIENT_PROFESSIONAL_SSO_TOKEN_POST_REQUEST"
export const CLIENT_PROFESSIONAL_SSO_TOKEN_POST_RECEIVE =
	"CLIENT_PROFESSIONAL_SSO_TOKEN_POST_RECEIVE"
export const CLIENT_PROFESSIONAL_SSO_TOKEN_POST_FAILURE =
	"CLIENT_PROFESSIONAL_SSO_TOKEN_POST_FAILURE"
