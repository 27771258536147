import { combineReducers } from "redux"
import dailyReducer, { HcaDailyExtractState } from "./daily/reducer"

export interface HcaExtractState {
	daily: HcaDailyExtractState
}

const hcaExtractReducer = combineReducers({
	daily: dailyReducer
})

export default hcaExtractReducer
