import * as ActionTypes from "./types"
import { toast } from "react-toastify"

export type BridgeRecord = {
	bridgeActivityId: number
	activityType: string
	activityName: string
	createDate: string
}

export type KafkaMessage = {
	topic: string
	offset: number
	value: string
}

export type KafkaQuery = {
	startOffset: number
	endOffset: number
	messages: KafkaMessage[]
	queryErrors: string
}

export type ImportUnpackTransformMerge = {
	uploadInProgress: boolean
	uploadErrors: string
}

export type KafkaProduce = {
	uploadInProgress: boolean
	uploadErrors: string
}

export type KafkaReplay = {
	replayInProgress: boolean
	replayErrors: string
}

export interface IntegrationsState {
	bridge: {
		records: BridgeRecord[]
		updated: boolean
	}
	unpackTransformMerge: ImportUnpackTransformMerge
	kafkaProduce: KafkaProduce
	kafkaReplay: KafkaReplay
	kafkaQuery: KafkaQuery
	fixPatientInProgress: boolean
}

const initialState: IntegrationsState = {
	bridge: {
		records: [],
		updated: false
	},
	unpackTransformMerge: {
		uploadInProgress: false,
		uploadErrors: ""
	},
	kafkaProduce: {
		uploadInProgress: false,
		uploadErrors: ""
	},
	kafkaReplay: {
		replayInProgress: false,
		replayErrors: ""
	},
	kafkaQuery: {
		startOffset: -1,
		endOffset: -1,
		messages: [],
		queryErrors: ""
	},
	fixPatientInProgress: false
}

function clientReducer(
	state: IntegrationsState = initialState,
	action: any
): IntegrationsState {
	switch (action.type) {
		case ActionTypes.GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_RECEIVE:
			return {
				...state,
				bridge: {
					records: action.payload,
					updated: true
				}
			}
		case ActionTypes.GET_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_FAILURE:
			toast.error("Unable to get bridge records")
			return state
		case ActionTypes.DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_FAILURE:
			toast.error("Unable to delete all bridge records")
			break
		case ActionTypes.DELETE_ALL_INTEGRATIONS_BRIDGE_ACTIVITY_RECORDS_RECEIVE:
			toast.success("All bridge records deleted successfully")
			return {
				...state,
				bridge: {
					...state.bridge,
					updated: false
				}
			}
		case ActionTypes.DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_FAILURE:
			toast.error("Unable to delete bridge record")
			break
		case ActionTypes.DELETE_INTEGRATIONS_BRIDGE_ACTIVITY_RECORD_RECEIVE:
			toast.success("Bridge record deleted successfully")
			return {
				...state,
				bridge: {
					...state.bridge,
					updated: false
				}
			}
		case ActionTypes.POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_REQUEST:
			return {
				...state,
				unpackTransformMerge: {
					...state.unpackTransformMerge,
					uploadInProgress: true,
					uploadErrors: ""
				}
			}
		case ActionTypes.POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_RECEIVE:
			toast.success("Successfully sent patients; check Slack for details")
			return {
				...state,
				unpackTransformMerge: {
					...state.unpackTransformMerge,
					uploadInProgress: false,
					uploadErrors: ""
				}
			}
		case ActionTypes.POST_INTEGRATIONS_UNPACK_TRANSFORM_MERGE_FAILURE:
			toast.error(
				"Unable to upload and process the patients. Check below and Slack."
			)
			return {
				...state,
				unpackTransformMerge: {
					...state.unpackTransformMerge,
					uploadInProgress: false,
					uploadErrors: action.payload.response.Message
				}
			}
		case ActionTypes.POST_INTEGRATIONS_KAFKA_PRODUCE_REQUEST:
			return {
				...state,
				kafkaProduce: {
					...state.kafkaProduce,
					uploadInProgress: true,
					uploadErrors: ""
				}
			}
		case ActionTypes.POST_INTEGRATIONS_KAFKA_PRODUCE_RECEIVE:
			toast.success(
				"Successfully produced message; updating Message list..."
			)
			return {
				...state,
				kafkaProduce: {
					...state.kafkaProduce,
					uploadInProgress: false,
					uploadErrors: ""
				}
			}
		case ActionTypes.POST_INTEGRATIONS_KAFKA_PRODUCE_FAILURE:
			toast.error(
				"Unable to produce a message into Kafka. Check below and Slack."
			)
			return {
				...state,
				kafkaProduce: {
					...state.kafkaProduce,
					uploadInProgress: false,
					uploadErrors: action.payload.response.Message
				}
			}
		case ActionTypes.GET_INTEGRATIONS_KAFKA_QUERY_REQUEST:
			return {
				...state,
				kafkaQuery: {
					...state.kafkaQuery
				}
			}
		case ActionTypes.GET_INTEGRATIONS_KAFKA_QUERY_RECEIVE:
			toast.success("Message list updated")
			return {
				...state,
				kafkaQuery: action.payload
			}
		case ActionTypes.GET_INTEGRATIONS_KAFKA_QUERY_FAILURE:
			toast.error("Unable to query Kafka. Check below and AWS.")
			return {
				...state,
				kafkaQuery: {
					...state.kafkaQuery,
					queryErrors: action.payload.response.Message
				}
			}
		case ActionTypes.POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_REQUEST:
			return {
				...state,
				kafkaReplay: {
					...state.kafkaReplay,
					replayInProgress: true,
					replayErrors: ""
				}
			}
		case ActionTypes.POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_RECEIVE:
			toast.success(
				"Successfully replayed offset message; updating Message list..."
			)
			return {
				...state,
				kafkaReplay: {
					...state.kafkaReplay,
					replayInProgress: false,
					replayErrors: ""
				}
			}
		case ActionTypes.POST_INTEGRATIONS_KAFKA_REPLAY_OFFSET_FAILURE:
			toast.error(
				"Unable to replay a message into Kafka. Check below and Slack."
			)
			return {
				...state,
				kafkaReplay: {
					...state.kafkaReplay,
					replayInProgress: false,
					replayErrors: action.payload.response.Message
				}
			}
	}
	return state
}

export default clientReducer
