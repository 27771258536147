import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import { DateTime } from "luxon"
import ExtractState from "../../../models/ExtractState"

export interface PhinExtractState extends ExtractState {
	digests: { name: string; createDate: string; count: number }[]
	sendToSftpInProgress: boolean
	undoDigestInProgress: any
}

const initialPhin = {
	digests: [],
	sendToSftpInProgress: false,
	undoDigestInProgress: {}
}

function phinReducer(state: any = initialPhin, action: any) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_PHIN_DIGESTS_RECEIVE:
			return {
				...state,
				digests: action.payload
			}
		case ActionTypes.EXTRACTS_PHIN_DIGESTS_FAILURE:
			toast.error("Unable to get PHIN digests")
			break
		case ActionTypes.EXTRACTS_PHIN_SEND_TO_SFTP_REQUEST:
			return {
				...state,
				sendToSftpInProgress: true
			}
		case ActionTypes.EXTRACTS_PHIN_SEND_TO_SFTP_RECEIVE:
			if (action.meta.status === 202) {
				toast.warn("PHIN extract already in progress")
			} else {
				toast.success(
					"PHIN extract started. See messages in relevant slack system channel for progress"
				)
			}
			return {
				...state,
				sendToSftpInProgress: false
			}
		case ActionTypes.EXTRACTS_PHIN_SEND_TO_SFTP_FAILURE:
			toast.error("Unable to send PHIN extract to SFTP")
			return {
				...state,
				sendToSftpInProgress: false
			}
		case ActionTypes.EXTRACTS_PHIN_UNDO_DIGEST_REQUEST:
			return {
				...state,
				undoDigestInProgress: {
					...state.undoDigestInProgress,
					[action.meta]: true
				}
			}
		case ActionTypes.EXTRACTS_PHIN_UNDO_DIGEST_RECEIVE:
			toast.success(
				`Successfuly undid PHIN digest for ${DateTime.fromISO(
					action.meta
				).toFormat("dd-MM-yyyy")}`
			)
			return {
				...state,
				undoDigestInProgress: {
					...state.undoDigestInProgress,
					[action.meta]: false
				}
			}
		case ActionTypes.EXTRACTS_PHIN_UNDO_DIGEST_FAILURE:
			toast.error(
				`Failed to undo PHIN digest for ${DateTime.fromISO(
					action.meta
				).toFormat("dd-MM-yyyy")}`
			)
			return {
				...state,
				undoDigestInProgress: {
					...state.undoDigestInProgress,
					[action.meta]: false
				}
			}
	}
	return state
}

export default phinReducer
