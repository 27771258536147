import * as ActionTypes from "./types"
import {
	getSearchProfessionalsFilters,
	resetProfessionalsSearchState
} from "../professionals/search/actions"
import {
	getSearchPatientsFilters,
	resetPatientsSearchState
} from "../patients/search/actions"
import TENANTED_API_REQUEST from "../../middleware/ApiRequestMiddleware"
import { clearUser } from "../professionals/user/actions"

export function resetSearchState(
	dispatch: (action: any) => Promise<any> | void
) {
	dispatch(resetPatientsSearchState())
	dispatch(resetProfessionalsSearchState())
	dispatch(clearUser())
	dispatch(getSearchProfessionalsFilters())
	dispatch(getSearchPatientsFilters())
}

export function tenantUpdated(): any {
	return async (dispatch: (action: any) => Promise<any> | void) => {
		resetSearchState(dispatch)
	}
}

export function overrideApiDate(overrideDate: string | undefined) {
	return {
		type: ActionTypes.SET_OVERRIDE_DATE,
		newDate: overrideDate
	}
}

export function updateTenantEnvironment(): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: `/api/admin/environment`,
			method: "GET",
			types: [
				ActionTypes.TENANT_ENVIRONMENT_REQUEST,
				ActionTypes.TENANT_ENVIRONMENT_RECEIVE,
				ActionTypes.TENANT_ENVIRONMENT_FAILURE
			]
		}
	}
}

export function sendOverdueEmails(enableEmails: boolean): any {
	const params = new URLSearchParams()
	params.append("enableEmails", enableEmails.toString())

	return {
		[TENANTED_API_REQUEST]: {
			endpoint:
				"/api/admin/automation/overdueemails?" + params.toString(),
			method: "GET",
			types: [
				ActionTypes.TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_REQUEST,
				{
					type: ActionTypes.TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_RECEIVE,
					meta: (action: any, state: any, response: any) => {
						return { status: response.status }
					}
				},
				ActionTypes.TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_FAILURE
			]
		}
	}
}

export function generateAssessments(
	verbose: boolean,
	enableEmails: boolean
): any {
	const params = new URLSearchParams()
	params.append("verbose", verbose.toString())
	params.append("enableEmails", enableEmails.toString())

	return {
		[TENANTED_API_REQUEST]: {
			endpoint:
				"/api/admin/automation/GenerateAssessments?" +
				params.toString(),
			method: "GET",
			types: [
				ActionTypes.TENANT_AUTOMATION_GENERATE_ASSESSMENTS_REQUEST,
				{
					type: ActionTypes.TENANT_AUTOMATION_GENERATE_ASSESSMENTS_RECEIVE,
					meta: (action: any, state: any, response: any) => {
						return { status: response.status }
					}
				},
				ActionTypes.TENANT_AUTOMATION_GENERATE_ASSESSMENTS_FAILURE
			]
		}
	}
}
