import * as ActionTypes from "./types"

export interface AuditEvent {
	auditEventId: number
	content: any
	contentType: string
	tenantCodeName: string
	createdBy: string
	createdOn: string
}

export interface AuditState {
	loading: boolean
	data: AuditEvent[]
	total: number
	types: string[]
}

const initial: AuditState = {
	loading: true,
	data: [],
	total: 0,
	types: []
}

function assessmentsReducer(state: AuditState = initial, action: any) {
	switch (action.type) {
		case ActionTypes.AUDIT_GET_EVENTS_REQUEST:
			return {
				...state,
				loading: true
			}
		case ActionTypes.AUDIT_GET_EVENTS_RECEIVE:
			return {
				...state,
				...action.payload,
				loading: false
			}
		case ActionTypes.AUDIT_GET_EVENTS_FAILURE:
			return {
				...state,
				loading: false,
				data: [],
				total: -1
			}
		case ActionTypes.AUDIT_GET_TYPES_REQUEST:
			return {
				...state,
				types: []
			}
		case ActionTypes.AUDIT_GET_TYPES_RECEIVE:
			return {
				...state,
				types: action.payload.types
			}
		case ActionTypes.AUDIT_GET_TYPES_FAILURE:
			return {
				...state,
				types: []
			}
	}
	return state
}

export default assessmentsReducer
