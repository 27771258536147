export const PATIENTS_SEARCH_FILTER_REQUEST = "PATIENTS_SEARCH_FILTER_REQUEST"
export const PATIENTS_SEARCH_FILTER_RECEIVE = "PATIENTS_SEARCH_FILTER_RECEIVE"
export const PATIENTS_SEARCH_FILTER_FAILURE = "PATIENTS_SEARCH_FILTER_FAILURE"

export const PATIENTS_SEARCH_FILTER_UPDATE_SELECTED =
	"PATIENTS_SEARCH_FILTER_UPDATE_SELECTED"

export const PATIENTS_SEARCH_RESET = "PATIENTS_SEARCH_RESET"

export const PATIENTS_SEARCH_FILTER_QUERY_UPDATE =
	"PATIENTS_SEARCH_FILTER_QUERY_UPDATE"

export const PATIENTS_SEARCH_TOGGLE_COLUMNS_UPDATE_SELECTED =
	"PATIENTS_SEARCH_TOGGLE_COLUMNS_UPDATE_SELECTED"

export const PATIENTS_SEARCH_REQUEST = "PATIENTS_SEARCH_REQUEST"
export const PATIENTS_SEARCH_RECEIVE = "PATIENTS_SEARCH_RECEIVE"
export const PATIENTS_SEARCH_FAILURE = "PATIENTS_SEARCH_FAILURE"

export const PATIENTS_SEARCH_PAGE_INDEX_UPDATE =
	"PATIENTS_SEARCH_PAGE_INDEX_UPDATE"
export const PATIENTS_SEARCH_PAGE_Size_AND_INDEX_UPDATE =
	"PATIENTS_SEARCH_PAGE_Size_AND_INDEX_UPDATE"
export const PATIENTS_SEARCH_SORTED_UPDATE = "PATIENTS_SEARCH_SORTED_UPDATE"
