import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import { SearchFilters } from "../../../models/Search"
import { ProfessionalUser } from "../../../models/User"

export interface SearchState {
	filters?: SearchFilters
	toggleColumns: Object
	searchQuery: string
	loading: boolean
	page: number
	pageSize: number
	data: ProfessionalUser[]
	totalResults: number
	sorted: any[]
}

const initialSearchProfessionals: SearchState = {
	filters: undefined,
	toggleColumns: {
		"User Type": true,
		"Treatment Centres": true,
		"GMC Number": true
	},
	searchQuery: "",
	loading: false,
	page: 0,
	pageSize: 10,
	data: [],
	totalResults: 0,
	sorted: [{ id: "email", desc: false }]
}

function searchProfessionalsReducer(
	state: SearchState = initialSearchProfessionals,
	action: any
) {
	switch (action.type) {
		case ActionTypes.PROFESSIONALS_SEARCH_FILTER_REQUEST:
			return {
				...state,
				filters: undefined,
				page: 0,
				loading: true
			}
		case ActionTypes.PROFESSIONALS_SEARCH_FILTER_RECEIVE:
			return {
				...state,
				filters: SearchFilters.CreateFromPayload(action.payload),
				loading: false
			}
		case ActionTypes.PROFESSIONALS_SEARCH_FILTER_UPDATE_SELECTED:
			return {
				...state,
				page: 0,
				filters:
					state.filters &&
					state.filters.updateSelectedInFilters(
						action.filterPipeline,
						action.checked
					)
			}
		case ActionTypes.PROFESSIONALS_SEARCH_RESET:
			return {
				...initialSearchProfessionals
			}
		case ActionTypes.PROFESSIONALS_SEARCH_FILTER_QUERY_UPDATE:
			return {
				...state,
				searchQuery: action.searchQuery
			}
		case ActionTypes.PROFESSIONALS_SEARCH_TOGGLE_COLUMNS_UPDATE_SELECTED:
			return {
				...state,
				toggleColumns: {
					...state.toggleColumns,
					[action.column]: action.selected
				}
			}
		case ActionTypes.PROFESSIONALS_SEARCH_PAGE_INDEX_UPDATE:
			return {
				...state,
				page: action.page
			}
		case ActionTypes.PROFESSIONALS_SEARCH_PAGE_Size_AND_INDEX_UPDATE:
			return {
				...state,
				page: action.page,
				pageSize: action.pageSize
			}
		case ActionTypes.PROFESSIONALS_SEARCH_SORTED_UPDATE:
			return {
				...state,
				sorted: action.sorted
			}
		case ActionTypes.PROFESSIONALS_SEARCH_REQUEST:
			return {
				...state,
				loading: true
			}
		case ActionTypes.PROFESSIONALS_SEARCH_RECEIVE:
			return {
				...state,
				loading: false,
				data: action.payload.professionals,
				totalResults: action.payload.total
			}
		case ActionTypes.PROFESSIONALS_SEARCH_FAILURE:
			toast.error("Unable to get professionals")
			return {
				...state,
				loading: false
			}
	}
	return state
}

export default searchProfessionalsReducer
