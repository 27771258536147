import * as ActionTypes from "./types"
import TENANTED_API_REQUEST from "../../middleware/ApiRequestMiddleware"

export function getAssessments(): any {
	return {
		[TENANTED_API_REQUEST]: {
			endpoint: "/api/admin/assessments",
			method: "GET",
			types: [
				ActionTypes.ASSESSMENTS_GET_FAILURE,
				ActionTypes.ASSESSMENTS_GET_RECEIVE,
				ActionTypes.ASSESSMENTS_GET_FAILURE
			]
		}
	}
}
