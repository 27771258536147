import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"
import ExtractState from "../../../../models/ExtractState"

const initialCadoganExtract: ExtractState = {
	downloadInProgress: false
}

function cadoganExtractReducer(
	state: ExtractState = initialCadoganExtract,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_CADOGAN_DOWNLOAD_REQUEST:
			return {
				...state,
				downloadInProgress: true
			}
		case ActionTypes.EXTRACTS_CADOGAN_DOWNLOAD_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_CADOGAN_DOWNLOAD_FAILURE:
			toast.error("Unable to download Cadogan extract")
			return {
				...state,
				downloadInProgress: false
			}
	}
	return state
}

export default cadoganExtractReducer
