import * as ActionTypes from "./types"
import { toast } from "react-toastify"

export type HcaDailyExtractState = {}

const initial: HcaDailyExtractState = {}

function hcaDailyExtractReducer(
	state: HcaDailyExtractState = initial,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_HCA_DAILY_EXTRACT_RECEIVE:
			toast.success("Started Hca daily extract. See slack for progress")
			return state
		case ActionTypes.EXTRACTS_HCA_DAILY_EXTRACT_FAILURE:
			toast.error("Unable to download Hca extract")
			return state
	}
	return state
}

export default hcaDailyExtractReducer
