export const UPDATE_PATIENT_CONDITION_REQUEST =
	"UPDATE_PATIENT_CONDITION_REQUEST"
export const UPDATE_PATIENT_CONDITION_RECEIVE =
	"UPDATE_PATIENT_CONDITION_RECEIVE"
export const UPDATE_PATIENT_CONDITION_FAILURE =
	"UPDATE_PATIENT_CONDITION_FAILURE"

export const ARCHIVE_PATIENT_CONDITION_REQUEST =
	"ARCHIVE_PATIENT_CONDITION_REQUEST"
export const ARCHIVE_PATIENT_CONDITION_RECEIVE =
	"ARCHIVE_PATIENT_CONDITION_RECEIVE"
export const ARCHIVE_PATIENT_CONDITION_FAILURE =
	"ARCHIVE_PATIENT_CONDITION_FAILURE"

export const ACTIVATE_PATIENT_CONDITION_REQUEST =
	"ACTIVATE_PATIENT_CONDITION_REQUEST"
export const ACTIVATE_PATIENT_CONDITION_RECEIVE =
	"ACTIVATE_PATIENT_CONDITION_RECEIVE"
export const ACTIVATE_PATIENT_CONDITION_FAILURE =
	"ACTIVATE_PATIENT_CONDITION_FAILURE"
