import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import fileDownload from "js-file-download"
import ExtractState from "../../../../models/ExtractState"

export interface TlcEpisodeNumberExtractState extends ExtractState {
	uploadInProgress: boolean
	uploadErrors: string
}
const initialTlcExtract: TlcEpisodeNumberExtractState = {
	downloadInProgress: false,
	uploadInProgress: false,
	uploadErrors: ""
}

function tlcExtractReducer(
	state: TlcEpisodeNumberExtractState = initialTlcExtract,
	action: any
) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_TLC_EPISODENUMBER_DOWNLOAD_REQUEST: {
			return {
				...state,
				downloadInProgress: true
			}
		}
		case ActionTypes.EXTRACTS_TLC_EPISODENUMBER_DOWNLOAD_RECEIVE:
			fileDownload(
				new Blob([action.payload.data], {
					type: action.payload.contentType
				}),
				action.payload.filename
			)
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_TLC_EPISODENUMBER_DOWNLOAD_FAILURE:
			toast.error("Unable to download TLC episode number extract")
			return {
				...state,
				downloadInProgress: false
			}
		case ActionTypes.EXTRACTS_TLC_EPISODENUMBER_UPLOAD_REQUEST: {
			return {
				...state,
				uploadInProgress: true,
				uploadErrors: ""
			}
		}
		case ActionTypes.EXTRACTS_TLC_EPISODENUMBER_UPLOAD_RECEIVE: {
			toast.success("Successfully uploaded episode numbers")
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: ""
			}
		}
		case ActionTypes.EXTRACTS_TLC_EPISODENUMBER_UPLOAD_FAILURE: {
			toast.error(
				"Unable to upload and process the Episode Number extract"
			)
			return {
				...state,
				uploadInProgress: false,
				uploadErrors: action.payload.response.Message
			}
		}
	}
	return state
}

export default tlcExtractReducer
