export const EMAIL_TEMPLATE_KEYS_GET_REQUEST = "EMAIL_TEMPLATE_KEYS_GET_REQUEST"
export const EMAIL_TEMPLATE_KEYS_GET_RECEIVE = "EMAIL_TEMPLATE_KEYS_GET_RECEIVE"
export const EMAIL_TEMPLATE_KEYS_GET_FAILURE = "EMAIL_TEMPLATE_KEYS_GET_FAILURE"

export const EMAIL_TEMPLATE_PREVIEW_GET_REQUEST =
	"EMAIL_TEMPLATE_PREVIEW_GET_REQUEST"
export const EMAIL_TEMPLATE_PREVIEW_GET_RECEIVE =
	"EMAIL_TEMPLATE_PREVIEW_GET_RECEIVE"
export const EMAIL_TEMPLATE_PREVIEW_GET_FAILURE =
	"EMAIL_TEMPLATE_PREVIEW_GET_FAILURE"

export const SMS_TEMPLATE_KEYS_GET_REQUEST = "SMS_TEMPLATE_KEYS_GET_REQUEST"
export const SMS_TEMPLATE_KEYS_GET_RECEIVE = "SMS_TEMPLATE_KEYS_GET_RECEIVE"
export const SMS_TEMPLATE_KEYS_GET_FAILURE = "SMS_TEMPLATE_KEYS_GET_FAILURE"

export const SMS_TEMPLATE_PREVIEW_GET_REQUEST =
	"SMS_TEMPLATE_PREVIEW_GET_REQUEST"
export const SMS_TEMPLATE_PREVIEW_GET_RECEIVE =
	"SMS_TEMPLATE_PREVIEW_GET_RECEIVE"
export const SMS_TEMPLATE_PREVIEW_GET_FAILURE =
	"SMS_TEMPLATE_PREVIEW_GET_FAILURE"
