import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import ExtractState from "../../../../models/ExtractState"

export interface NhsWalesNumberExtractState extends Omit<ExtractState, 'downloadInProgress'> {
    sendToSftpInProgress: boolean
}

const initialNhsWalesNhsNumber: NhsWalesNumberExtractState = {
    sendToSftpInProgress: false
}

function nhsWalesNhsNumberReducer(
    state: NhsWalesNumberExtractState = initialNhsWalesNhsNumber,
    action: any
) {
    switch (action.type) {
        case ActionTypes.EXTRACTS_NHSWALES_NHSNUMBER_REQUEST:
            return {
                ...state,
                sendToSftpInProgress: true
            }
        case ActionTypes.EXTRACTS_NHSWALES_NHSNUMBER_RECEIVE:
            toast.success("Started extract to SFTP. Check Slack for progress")
            return {
                ...state,
                sendToSftpInProgress: false
            }
        case ActionTypes.EXTRACTS_NHSWALES_NHSNUMBER_FAILURE:
            toast.error("Unable to start extract. Contact tech team")
            return {
                ...state,
                sendToSftpInProgress: false
            }
    }
    return state
}

export default nhsWalesNhsNumberReducer
