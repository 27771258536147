import * as ActionTypes from "./types"
import { toast } from "react-toastify"
import ExtractState from "../../../../models/ExtractState"

const initialSpireWeekly: ExtractState = {
	downloadInProgress: false,
	sendToSftpInProgress: false
}

function adHocReducer(state: ExtractState = initialSpireWeekly, action: any) {
	switch (action.type) {
		case ActionTypes.EXTRACTS_SPIRE_ADHOC_SEND_TO_SFTP_REQUEST:
			return {
				...state,
				sendToSftpInProgress: true
			}
		case ActionTypes.EXTRACTS_SPIRE_ADHOC_SEND_TO_SFTP_RECEIVE:
			toast.success("Check Slack for details")
			return {
				...state,
				sendToSftpInProgress: false
			}
		case ActionTypes.EXTRACTS_SPIRE_ADHOC_SEND_TO_SFTP_FAILURE:
			toast.error("Unable to send Spire Weekly extract to SFTP")
			return {
				...state,
				sendToSftpInProgress: false
			}
	}
	return state
}

export default adHocReducer
