import { RSAA } from "redux-api-middleware"
import { State } from "../state/reducer"
import { getTenantSubDomain } from "../components/tenancy/TenantHooks"

export const API_REQUEST = "@@mco_api_request"
export const TENANTED_API_REQUEST = "@@mco_tenanted_api_request"

function getApiUrl() {
	const host = window.location.host

	if (host === "localhost:3000") {
		return "api.localhost.com"
	}

	const domain = host.substring(host.indexOf(".") + 1)

	return `api.${domain}`
}

export const applyTenantedApiRequestMiddleware =
	(store: any) => (next: any) => (action: any) => {
		if (!action[TENANTED_API_REQUEST] && !action[API_REQUEST]) {
			return next(action)
		}

		const requestMarker = !!action[TENANTED_API_REQUEST]
			? TENANTED_API_REQUEST
			: API_REQUEST

		const { [requestMarker]: request, ...newAction } = action

		const headers = request.headers ? { ...request.headers } : {}
		const state = store.getState() as State
		headers["Authorization"] = `Bearer ${state.auth.accessToken}`

		if (requestMarker === TENANTED_API_REQUEST) {
			headers["mco-tenant-subdomain"] = getTenantSubDomain(
				window.location.pathname
			)
		}

		if (state.environment.overriddenDate) {
			headers["mco-overridden-date"] = state.environment.overriddenDate
		}

		request.headers = headers
		request.endpoint = `https://${getApiUrl()}${request.endpoint}`

		newAction[RSAA] = request
		return next(newAction)
	}

export default TENANTED_API_REQUEST
