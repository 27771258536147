import { State } from "../state/reducer"
import { useSelector } from "react-redux"

export const useNickname = () =>
	useSelector((state: State) => {
		const x = state.auth.user?.nickname
		if (!x) {
			throw new Error("No user details found in state")
		}
		return x.charAt(0).toUpperCase() + x.slice(1)
	})
