import * as ActionTypes from "./types"
import { toast } from "react-toastify"

export interface EventLogState {
	logs: Array<Object>
	count: number
	loadingEventLog: boolean
}

const initialEventLog: EventLogState = {
	logs: [],
	count: 0,
	loadingEventLog: true
}

function eventLogReducer(state: EventLogState = initialEventLog, action: any) {
	switch (action.type) {
		case ActionTypes.PATIENTS_EVENT_LOG_REQUEST:
			return {
				...state,
				loadingEventLog: true
			}
		case ActionTypes.PATIENTS_EVENT_LOG_RECEIVE:
			return {
				...state,
				logs: action.payload.logs,
				count: action.payload.count,
				loadingEventLog: false
			}
		case ActionTypes.PATIENTS_EVENT_LOG_FAILURE:
			toast.error("Unable to retrieve event log")
			return {
				...state,
				logs: [],
				count: 0,
				loadingEventLog: false
			}
	}
	return state
}

export default eventLogReducer
