import { combineReducers } from "redux"
import surveyAnswerExtractReducer from "./surveyanswer/reducer"
import adhocUriExtractReducer from "./adhoc/reducer"

const uriExtractReducer = combineReducers({
	surveyAnswer: surveyAnswerExtractReducer,
	adhoc: adhocUriExtractReducer
})

export default uriExtractReducer
