import { combineReducers } from "redux"
import weeklyReducer from "./weekly/reducer"
import ExtractState from "../../../models/ExtractState"
import episodeNumberReducer, {
	TlcEpisodeNumberExtractState
} from "./episodeNumber/reducer"
import worsenedPatientsReducer from "./worsenedPatients/reducer"

export interface TlcExtractState {
	weekly: ExtractState
	episodeNumber: TlcEpisodeNumberExtractState
	worsenedPatients: ExtractState
}

const tlcReducer = combineReducers({
	weekly: weeklyReducer,
	episodeNumber: episodeNumberReducer,
	worsenedPatients: worsenedPatientsReducer
})

export default tlcReducer
