import React, { Suspense } from "react"
import { Routes, Route } from "react-router-dom"
import { Alert } from "react-bootstrap"
import { useIsAuthenticated } from "../../hooks/AuthHooks"
import { Assessments } from "../assessments/Assessments"
import { useSelector } from "react-redux"
import { State } from "../../state/reducer"
import ErrorBoundary from "./ErrorBoundary"
import useIsProduction from "../../hooks/useIsProduction"
import KafkaManager from "../integrations/KafkaManager"
import LoadingMessage from "./LoadingMessage"

const Dashboard = React.lazy(() => import("../dashboard/Dashboard"))
const DevelopmentTools = React.lazy(() => import("../DevelopmentTools"))
const BulkAddConsultants = React.lazy(
	() => import("../professionals/BulkAddConsultants")
)
const BulkAddSecretaries = React.lazy(
	() => import("../professionals/BulkAddSecretaries")
)
const Northgate = React.lazy(() => import("../extracts/Northgate"))
const Phin = React.lazy(() => import("../extracts/Phin"))
const TenantSpecificExtract = React.lazy(
	() => import("../extracts/TenantSpecificExtract")
)
const AddProfessional = React.lazy(
	() => import("../professionals/AddProfessional")
)
const ShowProfessional = React.lazy(
	() => import("../professionals/ShowProfessional")
)
const AssessmentsWithIssuesList = React.lazy(
	() => import("../patients/AssessmentsWithIssuesList")
)
const AssessmentIssues = React.lazy(
	() => import("../patients/AssessmentIssues")
)
const EventLog = React.lazy(() => import("../patients/EventLog"))
const SearchProfessionals = React.lazy(
	() => import("../professionals/SearchProfessionals")
)
const SearchPatients = React.lazy(() => import("../patients/SearchPatients"))
const ShowPatient = React.lazy(() => import("../patients/ShowPatient"))
const BulkAddShares = React.lazy(() => import("../professionals/BulkAddShares"))
const BridgeRecords = React.lazy(() => import("../integrations/BridgeRecords"))
const BulkShare = React.lazy(
	() => import("../professionals/BulkShare/BulkShare")
)
const FakeData = React.lazy(() => import("../patients/FakeData"))
const ConfigSearch = React.lazy(() => import("../ConfigSearch/ConfigSearch"))
const EmailPreview = React.lazy(() => import("../templates/EmailPreview"))
const SmsPreview = React.lazy(() => import("../templates/SmsPreview"))
const Audit = React.lazy(() => import("../audit/Audit"))
const ProfessionalSso = React.lazy(() => import("../client/ProfessionalSso"))
const NhsNumberImport = React.lazy(() => import("../patients/NhsNumberImport"))
const UnpackTransformMerge = React.lazy(
	() => import("../integrations/UnpackTransformMerge")
)

const AuthenticatedRoutes = () => {
	const isDeveloper = useSelector(
		(state: State) => state.auth.user?.appMetadata?.isDeveloper || false
	)
	const isProduction = useIsProduction()
	const isAuthenticated = useIsAuthenticated()

	const DevToolsWarning = () => {
		return (
			<Alert variant="warning" className="my-3">
				<h3>Development Tools not available in production</h3>
			</Alert>
		)
	}

	if (!isAuthenticated) {
		return null
	}

	return (
		<Suspense fallback={<LoadingMessage>Loading</LoadingMessage>}>
			<ErrorBoundary
				errorPage={() => (
					<h1>
						Something has gone wrong. Try force refresh CTRL-F5 or
						Command-Shift-R. Failing that, let tech team know in
						Slack.
					</h1>
				)}
			>
				<Routes>
					<Route path="professionals/*">
						<Route path={`add`} element={<AddProfessional />} />
						<Route
							path={`bulkadd/consultants`}
							element={<BulkAddConsultants />}
						/>
						<Route
							path={`bulkadd/secretaries`}
							element={<BulkAddSecretaries />}
						/>
						<Route
							path={`sharing/bulkadd`}
							element={<BulkAddShares />}
						/>
						<Route
							path={`sharing/bulkshare`}
							element={<BulkShare />}
						/>
						<Route
							path={`:userId`}
							element={<ShowProfessional />}
						/>
						<Route
							path={`search`}
							element={<SearchProfessionals />}
						/>
					</Route>
					<Route path="patients/*">
						<Route path={`:userId`} element={<ShowPatient />} />
						<Route path={`search`} element={<SearchPatients />} />
						<Route
							path={`nhsNumbers`}
							element={<NhsNumberImport />}
						/>
						<Route
							path={`issues`}
							element={<AssessmentsWithIssuesList />}
						/>
						<Route
							path={`issues/:patientConditionId`}
							element={<AssessmentIssues />}
						/>
						<Route path={`events`} element={<EventLog />} />
					</Route>
					<Route path={`audit`} element={<Audit />} />
					<Route
						path={`extracts/northgate`}
						element={<Northgate />}
					/>
					<Route path={`extracts/phin`} element={<Phin />} />
					<Route
						path={`extracts/tenant`}
						element={<TenantSpecificExtract />}
					/>
					<Route
						path={`templates/emails/preview`}
						element={<EmailPreview />}
					/>
					<Route
						path={`integrations/bridge-records`}
						element={<BridgeRecords />}
					/>
					<Route
						path={`integrations/unpack-transform-merge`}
						element={<UnpackTransformMerge />}
					/>
					<Route
						path={`integrations/kafka-manager`}
						element={<KafkaManager />}
					/>
					<Route
						path={`templates/sms/preview`}
						element={<SmsPreview />}
					/>
					<Route
						path={`client/sso/professional`}
						element={<ProfessionalSso />}
					/>
					<Route
						path={`devtools`}
						element={
							isProduction && !isDeveloper ? (
								<DevToolsWarning />
							) : (
								<DevelopmentTools />
							)
						}
					/>
					<Route path={`assessments`} element={<Assessments />} />
					<Route
						path={`fakedata`}
						element={
							isProduction ? <DevToolsWarning /> : <FakeData />
						}
					/>
					<Route path={`config-search`} element={<ConfigSearch />} />
					<Route path={`*`} element={<Dashboard />} />
				</Routes>
			</ErrorBoundary>
		</Suspense>
	)
}

export default AuthenticatedRoutes
