import * as ActionTypes from "./types"

interface EmailTemplate {
	subject: string
	body: string
}

interface SmsTemplate {
	patientConditionId: number
	phoneNumber: string
	body: string
}

export interface TemplatePreviewState<T> {
	templateKeys: {
		name: string
		isOverriddenForTenant: boolean
		templateKey: string
	}[]
	templateKeysLoading: boolean
	templateKeysFailure: boolean
	template: T
	templateLoading: boolean
	templateFailure: boolean
}

export interface TemplatesState {
	emails: TemplatePreviewState<EmailTemplate>
	sms: TemplatePreviewState<SmsTemplate>
}

const initialTemplatesState: TemplatesState = {
	emails: {
		templateKeys: [],
		templateKeysLoading: true,
		templateKeysFailure: false,
		template: {
			subject: "",
			body: ""
		},
		templateLoading: false,
		templateFailure: false
	},
	sms: {
		templateKeys: [],
		templateKeysLoading: true,
		templateKeysFailure: false,
		template: {
			patientConditionId: -1,
			phoneNumber: "",
			body: ""
		},
		templateLoading: false,
		templateFailure: false
	}
}

function templatesReducer(
	state: TemplatesState = initialTemplatesState,
	action: any
): TemplatesState {
	switch (action.type) {
		case ActionTypes.EMAIL_TEMPLATE_KEYS_GET_REQUEST:
			return {
				...state,
				emails: {
					...state.emails,
					templateKeysLoading: true,
					templateKeysFailure: false
				}
			}
		case ActionTypes.EMAIL_TEMPLATE_KEYS_GET_RECEIVE:
			return {
				...state,
				emails: {
					...state.emails,
					templateKeys: [...action.payload],
					templateKeysLoading: false,
					templateKeysFailure: false
				}
			}
		case ActionTypes.EMAIL_TEMPLATE_KEYS_GET_FAILURE:
			return {
				...state,
				emails: {
					...state.emails,
					templateKeys: [],
					templateKeysLoading: false,
					templateKeysFailure: true
				}
			}
		case ActionTypes.EMAIL_TEMPLATE_PREVIEW_GET_REQUEST:
			return {
				...state,
				emails: {
					...state.emails,
					template: { body: "", subject: "" },
					templateLoading: true,
					templateFailure: false
				}
			}
		case ActionTypes.EMAIL_TEMPLATE_PREVIEW_GET_RECEIVE:
			return {
				...state,
				emails: {
					...state.emails,
					template: action.payload,
					templateLoading: false,
					templateFailure: false
				}
			}
		case ActionTypes.EMAIL_TEMPLATE_PREVIEW_GET_FAILURE:
			return {
				...state,
				emails: {
					...state.emails,
					template: { body: "", subject: "" },
					templateLoading: false,
					templateFailure: true
				}
			}
		case ActionTypes.SMS_TEMPLATE_KEYS_GET_REQUEST:
			return {
				...state,
				sms: {
					...state.sms,
					templateKeysLoading: true,
					templateKeysFailure: false
				}
			}
		case ActionTypes.SMS_TEMPLATE_KEYS_GET_RECEIVE:
			return {
				...state,
				sms: {
					...state.sms,
					templateKeys: [...action.payload],
					templateKeysLoading: false,
					templateKeysFailure: false
				}
			}
		case ActionTypes.SMS_TEMPLATE_KEYS_GET_FAILURE:
			return {
				...state,
				sms: {
					...state.sms,
					templateKeys: [],
					templateKeysLoading: false,
					templateKeysFailure: true
				}
			}
		case ActionTypes.SMS_TEMPLATE_PREVIEW_GET_REQUEST:
			return {
				...state,
				sms: {
					...state.sms,
					template: {
						patientConditionId: -1,
						phoneNumber: "",
						body: ""
					},
					templateLoading: true,
					templateFailure: false
				}
			}
		case ActionTypes.SMS_TEMPLATE_PREVIEW_GET_RECEIVE:
			return {
				...state,
				sms: {
					...state.sms,
					template: action.payload,
					templateLoading: false,
					templateFailure: false
				}
			}
		case ActionTypes.SMS_TEMPLATE_PREVIEW_GET_FAILURE:
			return {
				...state,
				sms: {
					...state.sms,
					template: {
						patientConditionId: -1,
						phoneNumber: "",
						body: ""
					},
					templateLoading: false,
					templateFailure: true
				}
			}
	}
	return state
}

export default templatesReducer
