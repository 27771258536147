import { AdminUser } from "./reducer"
import * as ActionTypes from "./types"

export function logout() {
	return {
		type: ActionTypes.LOGOUT
	}
}

export function finishAuthenticate(user: AdminUser, accessToken: string) {
	return {
		type: ActionTypes.FINISH_AUTHENTICATE,
		user,
		accessToken
	}
}
