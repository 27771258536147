import * as ActionTypes from "./types"

interface SelectOption {
	id: any
	name: string
}

export interface ChangeConditionState {
	medicalSpecialties: Array<SelectOption>
	problemAreas: Array<SelectOption>
	submitting: boolean
}

const initial: ChangeConditionState = {
	medicalSpecialties: [],
	problemAreas: [],
	submitting: false
}

function reducer(
	state: ChangeConditionState = initial,
	action: any
): ChangeConditionState {
	switch (action.type) {
		case ActionTypes.GET_MEDICAL_SPECIALTIES_FOR_CONSULTANTS_REQUEST:
			return {
				...state,
				medicalSpecialties: [],
				problemAreas: []
			}
		case ActionTypes.GET_MEDICAL_SPECIALTIES_FOR_CONSULTANTS_RECEIVE:
			return {
				...state,
				medicalSpecialties: action.payload
			}
		case ActionTypes.GET_MEDICAL_SPECIALTIES_FOR_CONSULTANTS_FAILURE:
			return state
		case ActionTypes.GET_MEDICAL_SPECIALTIES_FOR_CONDITIONS_REQUEST:
			return {
				...state,
				medicalSpecialties: [],
				problemAreas: []
			}
		case ActionTypes.GET_MEDICAL_SPECIALTIES_FOR_CONDITIONS_RECEIVE:
			return {
				...state,
				medicalSpecialties: action.payload
			}
		case ActionTypes.GET_MEDICAL_SPECIALTIES_FOR_CONDITIONS_FAILURE:
			return state
		case ActionTypes.GET_PROBLEM_AREAS_FOR_MEDICAL_SPECIALTY_REQUEST:
			return {
				...state,
				problemAreas: []
			}
		case ActionTypes.GET_PROBLEM_AREAS_FOR_MEDICAL_SPECIALTY_RECEIVE:
			return {
				...state,
				problemAreas: action.payload
			}
		case ActionTypes.CHANGE_CONDITION_REQUEST:
			return {
				...state,
				submitting: true
			}
		case ActionTypes.CHANGE_CONDITION_RECEIVE:
			return {
				...state,
				submitting: false
			}
		case ActionTypes.CHANGE_CONDITION_FAILURE:
			return {
				...state,
				submitting: false
			}
	}
	return state
}

export default reducer
